import axios from "axios";
import { BASE_URL, BASE_URL_CONTACT } from "../constants/apiConstants";
export const detailservices = {
  getDetailList(companyId) {
    return axios({
      url: `${BASE_URL}company-detail/${companyId}`,
      method: "GET",
    });
  },
};

export const detailpresssevices = {
  getPressList(pressId) {
    return axios({
      url: `${BASE_URL}press/${pressId}`,
      method: "GET",
    });
  },
};
