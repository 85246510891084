import React, { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolioList } from "../../../../reducers/homeSlice";
import bg from "../../../../assets/homepage_img/porti_bg.png";
import PortfolioTextAnimation from "./PortfolioTextAnimation";
import "./portfolio.scss";
import { Link } from "react-router-dom";

const NewPortfolio = () => {
  const [sWInfrastructureLoadMore, setSWInfrastructureLoadMore] =
    useState(false);
  const [digitalSupplyChainLoadMore, setDigitalSupplyChainLoadMore] =
    useState(false);
  const [sustainableMegacityLoadMore, setSustainableMegacityLoadMore] =
    useState(false);

  // index postiton
  const [
    sWInfrastructureLoadMorePosition,
    setSWInfrastructureLoadMorePosition,
  ] = useState(false);
  const [
    digitalSupplyChainLoadMorePosition,
    setDigitalSupplyChainLoadMorePosition,
  ] = useState(false);
  const [
    sustainableMegacityLoadMorePosition,
    setSustainableMegacityLoadMorePosition,
  ] = useState(false);

  // get Data from homeSlice
  const viewAll = useSelector((state) => state.homeSlice.portfolios);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPortfolioList());
  }, []);

  // filter Digital Supply Chain data
  const digitalSupplyChain = viewAll?.filter((logo) => {
    return logo.acf.category.label === "Digital Supply Chain";
  });
  const limitDigitalSupplyChain = digitalSupplyChain?.slice(0, 16);

  const digitalSupplyChainDescription =
    digitalSupplyChain?.shift()?.acf?.category?.value + ".";

  // filter Sustainable Megacity data
  const sustainableMegacity = viewAll?.filter((logo) => {
    return logo.acf.category.label === "Sustainable Megacity";
  });
  const limitSustainableMegacity = sustainableMegacity?.slice(0, 16);

  const sustainableMegacityDescription =
    sustainableMegacity?.shift()?.acf?.category?.value + ".";

  // filter SW Infrastructure data
  const sWInfrastructure = viewAll?.filter((logo, i) => {
    return logo.acf.category.label === "SW Infrastructure";
  });
  const limitSWInfrastructure = sWInfrastructure?.slice(0, 16);

  const sWInfrastructureDescription =
    sWInfrastructure?.shift()?.acf?.category?.value + ".";
  // scroll to view

  function printMousePos(e, name) {
    let cursorY = e.pageY;
    if (name === "SWInfrastructure") {
      setSWInfrastructureLoadMorePosition(cursorY);
    }
    if (name === "digitalSupplyChain") {
      setDigitalSupplyChainLoadMorePosition(cursorY);
    }
    if (name === "sustainableMegacity") {
      setSustainableMegacityLoadMorePosition(cursorY);
    }
  }
  const scrollTo = (name) => {
    if (name === "sWInfrastructure") {
      window.scrollTo(0, sWInfrastructureLoadMorePosition - 500);
    }
    if (name === "digitalSupplyChain") {
      window.scrollTo(0, digitalSupplyChainLoadMorePosition - 500);
    }
    if (name === "sustainableMegacity") {
      window.scrollTo(0, sustainableMegacityLoadMorePosition - 500);
    }
  };

  const [inView, setInView] = useState(false);

  return (
    <>
      <div className="bg-portfolioBg pt-[2px] sm:pt-[7rem] md:pt-[2px] mt-[125px] sm:mt-[200px] xl:mt-[300px] relative">
        {/* image */}
        <div className="absolute">
          <img
            src={bg}
            alt="background"
            className="w-screen h-auto object-contain"
          />
        </div>

        <div id="portfolios" className="mt-[43%] lg:mt-[33%]">
          <div id="mobilePortfolios" class="pt-[7rem] md:pt-[7rem] md:mt-0">
            <div className="bg-portfolioBg pt-[2px]">
              {/* text content */}
              <div className="relative">
                <div className="h-[264px] sm:h-[426px] md:mt-[107px] textId">
                  <div inView={inView}>
                    <InView onChange={setInView} triggerOnce>
                      {({ ref }) => (
                        <div ref={ref}>
                          {inView ? <PortfolioTextAnimation /> : <></>}
                        </div>
                      )}
                    </InView>
                  </div>
                </div>
              </div>

              <div
                id="tabsID"
                className="md:flex items-center justify-center pb-[125px]  sm:pb-[200px] xl:pb-[300px] pt-[171px] sm:pt-0 xl:pt-[56px]  bg-portfolioBg"
              >
                <div className="mx-[35px] md:mx-[20px]">
                  {/* SW Infrastructure */}
                  <div
                    id="sWInfrastructureID"
                    className="md:flex flex-col items-center pt-[20px] sm:pt-[64px]"
                  >
                    <div className="absolute left-[36px] md:static popinsFont text-[14px] sm:text-[20px] xl:text-[24px]  font-semibold  pb-[6px] sm:pb-[11px] flex items-center justify-center text-white border-white border-b-[1px] mx-auto cursor-default">
                      <p>SW Infrastructure</p>
                    </div>
                    <div className="flex flex-wrap justify-left gap-y-[26px] sm:gap-y-[40px]  xl:gap-y-[75px] sm:gap-x-[36px]  gap-x-[25px] md:mx-auto ml-0 w-[285px] sm:w-[464px] md:w-[432px] lg:w-[630px] xl:w-[909px] 2xl:w-[908px]  mt-[55px] md:mt-[30px]">
                      <p className="text-left md:text-center text-[13px] xl:text-[20px] popinsFont font-normal text-hoverGrey mb-0">
                        {sWInfrastructureDescription !== "undefined."
                          ? sWInfrastructureDescription
                          : ""}
                      </p>
                    </div>
                    <div className="flex flex-wrap justify-left gap-y-[26px] sm:gap-y-[40px]  xl:gap-y-[75px] sm:gap-x-[36px]  gap-x-[25px] md:mx-auto ml-0 w-[285px] sm:w-[464px] md:w-[432px] lg:w-[630px] xl:w-[909px] 2xl:w-[908px] mt-[40px] md:mt-[60px] xl:mt-[70px]">
                      {sWInfrastructureLoadMore
                        ? sWInfrastructure?.map((logo, i) => {
                            return (
                              <>
                                <Link
                                target="_blank"
                                  to={
                                    logo?.acf?.company_detail === undefined
                                      ? ""
                                      : `/companydetail/${logo?.acf?.company_detail}`
                                  }
                                >
                                  <div
                                    id="porfolioId"
                                    className=" flex flex-col items-start md:items-center opacity-80 hover:opacity-100"
                                  >
                                    <div className="w-[52px] h-[31.2px]  sm:w-[81px] sm:h-[48.6px] lg:w-[130x] lg:h-[78px] xl:w-[200px] xl:h-[120px]">
                                      <img
                                        src={logo?.acf?.image}
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                      />
                                    </div>
                                    {/* <div id="dotParent" className="flex">
                                        {[...Array(Number(logo?.acf?.rank))].map(
                                          () => {
                                            return (
                                              <div
                                                id="dotId"
                                                className="w-[3px] h-[3px] sm:w-[5px] sm:h-[5px] xl:w-[10px] xl:h-[10px] mt-[10px] sm:mt-[14px] xl:mt-[16px] ml-[2px] sm:ml-[4px] xl:ml-[8px] bg-[#fff] rounded-full "
                                              ></div>
                                            );
                                          }
                                        )}
                                      </div> */}
                                  </div>
                                </Link>

                                {/* {(i + 1) % 4 === 0 ? (
                                  <hr className="w-full bg-white" />
                                ) : (
                                  <></>
                                )} */}
                              </>
                            );
                          })
                        : limitSWInfrastructure?.map((logo, i) => {
                            return (
                              <>
                                <Link
                                target="_blank"
                                  to={
                                    logo?.acf?.company_detail === undefined
                                      ? ""
                                      : `/companydetail/${logo?.acf?.company_detail}`
                                  }
                                >
                                  <div
                                    id="porfolioId"
                                    className=" flex flex-col items-start md:items-center opacity-80 hover:opacity-100"
                                  >
                                    <div className="w-[52px] h-[31.2px]  sm:w-[81px] sm:h-[48.6px] lg:w-[130px] lg:h-[78px] xl:w-[200px] xl:h-[120px]">
                                      <img
                                        src={logo?.acf?.image}
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                      />
                                    </div>
                                    {/* <div id="dotParent" className="flex">
                                        {[...Array(Number(logo?.acf?.rank))].map(
                                          () => {
                                            return (
                                              <div
                                                id="dotId"
                                                className="w-[3px] h-[3px] sm:w-[5px] sm:h-[5px] xl:w-[10px] xl:h-[10px] mt-[10px] sm:mt-[14px] xl:mt-[16px] ml-[2px] sm:ml-[4px] xl:ml-[8px] bg-[#fff] rounded-full "
                                              ></div>
                                            );
                                          }
                                        )}
                                      </div> */}
                                  </div>
                                </Link>

                                {/* {(i + 1) % 4 === 0 ? (
                                  <hr className="w-full bg-white" />
                                ) : (
                                  <></>
                                )} */}
                              </>
                            );
                          })}
                    </div>

                    {sWInfrastructure?.length > 16 ? (
                      <div className="mt-[26px] sm:mt-[38px] xl:mt-[75px]">
                        <span
                          onClick={(e) => {
                            setSWInfrastructureLoadMore(
                              !sWInfrastructureLoadMore
                            );
                            printMousePos(e, "SWInfrastructure");
                          }}
                          className="border-b-[1px] border-white text-white cursor-pointer xl:text-[20px] text-[10px] sm:text-[14px] opacity-80 hover:opacity-100 transition-all"
                        >
                          {sWInfrastructureLoadMore ? (
                            <p
                              className="underline text-center"
                              onClick={() => {
                                scrollTo("sWInfrastructure");
                              }}
                            >
                              Hide
                            </p>
                          ) : (
                            <p className="underline text-center">View more</p>
                          )}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* Digital Supply Chain */}
                  <div
                    id="digitalSupplyChainID"
                    class="md:flex flex-col items-center  mt-[35px] sm:mt-[80px] xl:mt-[155px] pt-[20px]"
                  >
                    <div className="absolute left-[36px] md:static popinsFont text-[14px] sm:text-[20px] xl:text-[24px]  font-semibold pb-[6px] sm:pb-[11px] flex items-center justify-center text-white border-white border-b-[1px] mx-auto cursor-default">
                      <p>Digital Supply Chain</p>
                    </div>

                    <div className="flex flex-wrap justify-left gap-y-[26px] sm:gap-y-[40px]  xl:gap-y-[75px] sm:gap-x-[36px]  gap-x-[25px] md:mx-auto ml-0 w-[285px] sm:w-[464px] md:w-[432px] lg:w-[630px] xl:w-[909px] 2xl:w-[908px]   mt-[55px] md:mt-[30px]">
                      <p className="text-left md:text-center text-[13px] xl:text-[20px] popinsFont font-normal text-hoverGrey mb-0">
                        {digitalSupplyChainDescription !== "undefined."
                          ? digitalSupplyChainDescription
                          : ""}
                      </p>
                    </div>

                    <div className="flex flex-wrap justify-left gap-y-[26px] sm:gap-y-[40px]  xl:gap-y-[75px] sm:gap-x-[36px]  gap-x-[25px] md:mx-auto ml-0 w-[285px] sm:w-[464px] md:w-[432px] lg:w-[630px] xl:w-[909px] 2xl:w-[908px] mt-[40px] md:mt-[60px] xl:mt-[70px] ">
                      {digitalSupplyChainLoadMore
                        ? digitalSupplyChain?.map((logo, i) => {
                            return (
                              <>
                                <Link
                                target="_blank"
                                  to={
                                    logo?.acf?.company_detail === undefined
                                      ? ""
                                      : `/companydetail/${logo?.acf?.company_detail}`
                                  }
                                >
                                  <div
                                    id="porfolioId"
                                    className=" flex flex-col items-start md:items-center opacity-80 hover:opacity-100"
                                  >
                                    <div className="w-[52px] h-[31.2px]  sm:w-[81px] sm:h-[48.6px] lg:w-[130px] lg:h-[78px] xl:w-[200px] xl:h-[120px]">
                                      <img
                                        src={logo?.acf?.image}
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                      />
                                    </div>
                                    {/* <div id="dotParent" className="flex">
                                        {[...Array(Number(logo?.acf?.rank))].map(
                                          () => {
                                            return (
                                              <div
                                                id="dotId"
                                                className="w-[3px] h-[3px] sm:w-[5px] sm:h-[5px] xl:w-[10px] xl:h-[10px] mt-[10px] sm:mt-[14px] xl:mt-[16px] ml-[2px] sm:ml-[4px] xl:ml-[8px] bg-[#fff] rounded-full "
                                              ></div>
                                            );
                                          }
                                        )}
                                      </div> */}
                                  </div>
                                </Link>

                                {/* {(i + 1) % 4 === 0 ? (
                                  <hr className="w-full bg-white" />
                                ) : (
                                  <></>
                                )} */}
                              </>
                            );
                          })
                        : limitDigitalSupplyChain?.map((logo, i) => {
                            return (
                              <>
                                <Link
                                target="_blank"
                                  to={
                                    logo?.acf?.company_detail === undefined
                                      ? ""
                                      : `/companydetail/${logo?.acf?.company_detail}`
                                  }
                                >
                                  <div
                                    id="porfolioId"
                                    className=" flex flex-col items-start md:items-center opacity-80 hover:opacity-100"
                                  >
                                    <div className="w-[52px] h-[31.2px]  sm:w-[81px] sm:h-[48.6px] lg:w-[130px] lg:h-[78px] xl:w-[200px] xl:h-[120px]">
                                      <img
                                        src={logo?.acf?.image}
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                      />
                                    </div>
                                    {/* <div id="dotParent" className="flex">
                                        {[...Array(Number(logo?.acf?.rank))].map(
                                          () => {
                                            return (
                                              <div
                                                id="dotId"
                                                className="w-[3px] h-[3px] sm:w-[5px] sm:h-[5px] xl:w-[10px] xl:h-[10px] mt-[10px] sm:mt-[14px] xl:mt-[16px] ml-[2px] sm:ml-[4px] xl:ml-[8px] bg-[#fff] rounded-full "
                                              ></div>
                                            );
                                          }
                                        )}
                                      </div> */}
                                  </div>
                                </Link>

                                {/* {(i + 1) % 4 === 0 ? (
                                  <hr className="w-full bg-white" />
                                ) : (
                                  <></>
                                )} */}
                              </>
                            );
                          })}
                    </div>

                    {digitalSupplyChain?.length > 16 ? (
                      <div className="mt-[26px] sm:mt-[38px] xl:mt-[75px]">
                        <span
                          onClick={(e) => {
                            setDigitalSupplyChainLoadMore(
                              !digitalSupplyChainLoadMore
                            );
                            printMousePos(e, "digitalSupplyChain");
                          }}
                          className="border-b-[1px] border-white text-white cursor-pointer xl:text-[20px] text-[10px] sm:text-[14px] opacity-80 hover:opacity-100 transition-all"
                        >
                          {digitalSupplyChainLoadMore ? (
                            <p
                              className="underline text-center"
                              onClick={() => {
                                scrollTo("digitalSupplyChain");
                              }}
                            >
                              Hide
                            </p>
                          ) : (
                            <p className="underline text-center">View more</p>
                          )}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* Sustainable Megacity */}
                  <div
                    id="sustainableMegacityID"
                    className="md:flex flex-col items-center  mt-[35px] sm:mt-[80px] xl:mt-[155px] pt-[20px]"
                  >
                    <div className="absolute left-[36px] md:static popinsFont text-[14px] sm:text-[20px] xl:text-[24px]  font-semibold pb-[6px] sm:pb-[11px] flex items-center justify-center text-white border-white border-b-[1px] mx-auto cursor-default">
                      <p>Sustainable Megacity</p>
                    </div>

                    <div className="flex flex-wrap justify-left gap-y-[26px] sm:gap-y-[40px]  xl:gap-y-[75px] sm:gap-x-[36px]  gap-x-[25px] md:mx-auto ml-0 w-[285px] sm:w-[464px] md:w-[432px] lg:w-[630px] xl:w-[909px] 2xl:w-[908px]  mt-[55px] md:mt-[30px]">
                      <p className="text-left md:text-center text-[13px] xl:text-[20px] popinsFont font-normal text-hoverGrey mb-0">
                        {sustainableMegacityDescription !== "undefined."
                          ? sustainableMegacityDescription
                          : ""}
                      </p>
                    </div>

                    <div className="flex flex-wrap justify-left gap-y-[26px] sm:gap-y-[40px]  xl:gap-y-[75px] sm:gap-x-[36px]  gap-x-[25px] md:mx-auto ml-0 w-[285px] sm:w-[464px] md:w-[432px] lg:w-[630px] xl:w-[909px] 2xl:w-[908px] mt-[40px] md:mt-[60px] xl:mt-[70px]">
                      {sustainableMegacityLoadMore
                        ? sustainableMegacity?.map((logo, i) => {
                            return (
                              <>
                                <Link
                                target="_blank"
                                  to={
                                    logo?.acf?.company_detail === undefined
                                      ? ""
                                      : `/companydetail/${logo?.acf?.company_detail}`
                                  }
                                >
                                  <div
                                    id="porfolioId"
                                    className=" flex flex-col items-start md:items-center opacity-80 hover:opacity-100"
                                  >
                                    <div className="w-[52px] h-[31.2px]  sm:w-[81px] sm:h-[48.6px] lg:w-[130px] lg:h-[78px] xl:w-[200px] xl:h-[120px]">
                                      <img
                                        src={logo?.acf?.image}
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                      />
                                    </div>
                                    {/* <div id="dotParent" className="flex">
                                        {[...Array(Number(logo?.acf?.rank))].map(
                                          () => {
                                            return (
                                              <div
                                                id="dotId"
                                                className="w-[3px] h-[3px] sm:w-[5px] sm:h-[5px] xl:w-[10px] xl:h-[10px] mt-[10px] sm:mt-[14px] xl:mt-[16px] ml-[2px] sm:ml-[4px] xl:ml-[8px] bg-[#fff] rounded-full "
                                              ></div>
                                            );
                                          }
                                        )}
                                      </div> */}
                                  </div>
                                </Link>

                                {/* {(i + 1) % 4 === 0 ? (
                                  <hr className="w-full bg-white" />
                                ) : (
                                  <></>
                                )} */}
                              </>
                            );
                          })
                        : limitSustainableMegacity?.map((logo, i) => {
                            return (
                              <>
                                <Link
                                target="_blank"
                                  to={
                                    logo?.acf?.company_detail === undefined
                                      ? ""
                                      : `/companydetail/${logo?.acf?.company_detail}`
                                  }
                                >
                                  <div
                                    id="porfolioId"
                                    className=" flex flex-col items-start md:items-center opacity-80 hover:opacity-100"
                                  >
                                    <div className="w-[52px] h-[31.2px]  sm:w-[81px] sm:h-[48.6px] lg:w-[130px] lg:h-[78px] xl:w-[200px] xl:h-[120px]">
                                      <img
                                        src={logo?.acf?.image}
                                        alt="logo"
                                        className="w-full h-full object-contain"
                                      />
                                    </div>
                                    {/* <div id="dotParent" className="flex">
                                        {[...Array(Number(logo?.acf?.rank))].map(
                                          () => {
                                            return (
                                              <div
                                                id="dotId"
                                                className="w-[3px] h-[3px] sm:w-[5px] sm:h-[5px] xl:w-[10px] xl:h-[10px] mt-[10px] sm:mt-[14px] xl:mt-[16px] ml-[2px] sm:ml-[4px] xl:ml-[8px] bg-[#fff] rounded-full "
                                              ></div>
                                            );
                                          }
                                        )}
                                      </div> */}
                                  </div>
                                </Link>

                                {/* {(i + 1) % 4 === 0 ? (
                                  <hr className="w-full bg-white" />
                                ) : (
                                  <></>
                                )} */}
                              </>
                            );
                          })}
                    </div>

                    {sustainableMegacity?.length > 16 ? (
                      <div className="mt-[26px] sm:mt-[38px] xl:mt-[75px]">
                        <span
                          onClick={(e) => {
                            setSustainableMegacityLoadMore(
                              !sustainableMegacityLoadMore
                            );
                            printMousePos(e, "sustainableMegacity");
                          }}
                          className="border-b-[1px] border-white text-white cursor-pointer xl:text-[20px] text-[10px] sm:text-[14px] opacity-80 hover:opacity-100 transition-all"
                        >
                          {sustainableMegacityLoadMore ? (
                            <p
                              className="underline text-center"
                              onClick={() => {
                                scrollTo("sustainableMegacity");
                              }}
                            >
                              Hide
                            </p>
                          ) : (
                            <p className="underline text-center">View more</p>
                          )}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPortfolio;
