import { useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import "./listlogo.scss";
ListLogoPartner.propTypes = {};

function ListLogoPartner({ listPartners }) {
  const matchTablet_PC = useMediaQuery("(min-width: 640px)");

  const [dataList, setDataList] = useState([]);
  const [loadmore, setLoadmore] = useState(true);
  const [positionLoadMore, setPositionLoadMore] = useState();
  const heightFrame = useRef(null);

  // GET DATA
  useEffect(() => {
    if (listPartners) {
      const arraysort = [...listPartners];
      const arrnewsort = arraysort?.sort((a, b) =>
        a?.acf?.name.localeCompare(b?.acf?.name)
      );
      setDataList([...arrnewsort]);
    }
  }, [listPartners]);

  const ToLoadMore = (e) => {
    setLoadmore((loadmore) => !loadmore);

    if (!loadmore) {
      window.scrollTo(0, positionLoadMore - 700);
      document.getElementsByClassName("column-logo")[0].style.height = "400px";
      document.getElementsByClassName("column-logo")[0].style.overflow =
        "hidden";
    } else {
      let cursorY = e.pageY;
      setPositionLoadMore(cursorY);
      document.getElementsByClassName("column-logo")[0].style.height = "auto";
      document.getElementsByClassName("column-logo")[0].style.overflow = "auto";
    }
  };
  const ToLoadMoreMB = (e) => {
    setLoadmore((loadmore) => !loadmore);

    if (!loadmore) {
      window.scrollTo(0, positionLoadMore - 700);
      for (let i = 36; i < listPartners?.length; i++) {
        document.getElementsByClassName("frameimglogo")[i].style.display =
          "none";
      }
    } else {
      let cursorY = e.pageY;
      setPositionLoadMore(cursorY);
      for (let i = 36; i < listPartners?.length; i++) {
        document.getElementsByClassName("frameimglogo")[i].style.display =
          "block";
      }
    }
  };

  const [countLogo, setCountLogo] = useState(0);

  useEffect(() => {
    const getNumberOneRow = getNumPerRow("#gridlogo");
    const numberRowExist = matchTablet_PC ? 4 : 6;
    const countLogoHide = dataList?.length - getNumberOneRow * numberRowExist;
    setCountLogo(countLogoHide);
  });

  const getNumPerRow = (selector) => {
    const grid = Array.from(document.querySelector(selector)?.children);
    const baseOffset = grid[0]?.offsetTop;
    const breakIndex = grid.findIndex((item) => item?.offsetTop > baseOffset);
    return breakIndex === -1 ? grid.length : breakIndex;
  };

  return (
    <div className="container-logo-partner pb-[125px] md:pb-[310px] xl:pb-[300px] pt-[0px] sm:pt-[0px] xl:pt-[30px] 2xl:pt-[100px] ml-[34px] mr-[34px] sm:ml-[34px] sm:mr-[72px] md:ml-[117px] md:mr-[117px] 2xl:ml-[300px] 2xl:mr-[170px] ">
      <div className="column-logo" ref={heightFrame} id="gridlogo">
        {dataList.map((item, index) => (
          <div
            className={"frameimglogo opacity-80 hover:opacity-100"}
            id={index == 0 ? "frameback" : ""}
            style={
              listPartners?.length > 36 && !matchTablet_PC && index > 35
                ? { display: "none" }
                : {}
            }
          >
            {item?.acf?.linkWebsite.length === 0 ? (
              <a className="cursor-default">
                <img src={item?.acf?.image} className="hover:opacity-80" />
              </a>
            ) : (
              <a href={item?.acf?.linkWebsite} target="_blank">
                <img src={item?.acf?.image} />
              </a>
            )}
          </div>
        ))}
      </div>
      <div className="framebutton-logopartner" id="frambutton">
        {matchTablet_PC ? (
          <button
            className="m-auto mt-[40px] sm:mt-[40px] xl:mt-[100px] text-[10px] sm:text-[14px] xl:text-[20px] underline  opacity-80 hover:opacity-100 cursor-pointer"
            style={
              heightFrame?.current?.clientHeight <
              heightFrame?.current?.scrollHeight
                ? {
                    display: "block",
                    color: "white",
                  }
                : heightFrame?.current?.clientHeight ==
                    heightFrame?.current?.scrollHeight && !loadmore
                ? {
                    display: "block",
                    color: "white",
                  }
                : {
                    display: "none",
                  }
            }
            onClick={(e) => ToLoadMore(e)}
          >
            {loadmore ? <p>View More {countLogo} </p> : <p>Hide</p>}
          </button>
        ) : (
          <button
            className="m-auto mt-[40px] sm:mt-[40px] xl:mt-[100px] text-[10px] sm:text-[14px] xl:text-[20px] underline  opacity-80 hover:opacity-100 cursor-pointer"
            style={
              listPartners?.length > 36
                ? { display: "block", color: "white" }
                : { display: "none" }
            }
            onClick={(e) => ToLoadMoreMB(e)}
          >
            {loadmore ? <p>View More {countLogo} </p> : <p>Hide</p>}
          </button>
        )}
      </div>
    </div>
  );
}

export default ListLogoPartner;
