import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { detailpresssevices, detailservices } from "../services/detailService";

const initialState = {

  pressList: null
};

const pressDetailSlice = createSlice({
  name: "Press Detail Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPressList.fulfilled, (state, action) => {
        state.pressList = [...state.pressList,...action.payload];
      })
      .addCase(getPressList.rejected, (state, action) => {
        console.log("error");
      })

  },
});
// export const { getList } = homeSlice.actions;
export default pressDetailSlice.reducer;

export const getPressList = createAsyncThunk("getPressList", async (pressId) => {
    const response = await detailpresssevices.getPressList(pressId);
    return response.data;
  });


