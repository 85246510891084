import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { detailservices } from "../services/detailService";

const initialState = {

  detailList: null,
  loading: false
};

const companyDetailSlice = createSlice({
  name: "Company Detail Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDetailList.pending, (state, action) => {
        state.loading = true;
      }) 
      .addCase(getDetailList.fulfilled, (state, action) => {
        state.detailList = action.payload;
        state.loading = false;
      })
      .addCase(getDetailList.rejected, (state, action) => {
        console.log("error");
        state.loading = false;
      })

  },
});
// export const { getList } = homeSlice.actions;
export default companyDetailSlice.reducer;

export const getDetailList = createAsyncThunk("getDetailList", async (companyId) => {
  const response = await detailservices.getDetailList(companyId);
  return response.data;
});




